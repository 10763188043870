import { StyledSvg } from "baseui/icon";
import React from "react";

export const HTMLIcon = (props: any) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99674 12.0156L10.7768 11.1994L11.1525 6.79389H6.20713L6.08418 5.32778H11.2754L11.412 3.89056H4.58144L4.96396 8.23111H9.67024L9.51314 10.0944L7.99674 10.5278L6.48035 10.0944L6.38472 8.89556H5.0186L5.21669 11.1994L7.99674 12.0156ZM2.58008 1.5H13.4134L12.4366 13.2L7.99674 14.5L3.55685 13.2L2.58008 1.5Z"
        fill="#E44D26"
      />
    </svg>
  </StyledSvg>
);
