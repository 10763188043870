import { StyledSvg } from "baseui/icon";
import { defaultTo, omit } from "lodash";
import React from "react";

export const NpmIcon = (props: React.ComponentProps<typeof StyledSvg> & { colored?: boolean }) => {
  const red = defaultTo(props.colored, true) ? "#D50000" : "currentColor";

  return (
    <StyledSvg viewBox="0 0 16 16" {...omit(props, "colored")}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.83008 14.1695V1.83057H14.169V14.1695H1.83008ZM4.15072 4.14281V11.8386H8.03937V5.7475H10.3054V11.8398H11.8481V4.14281H4.15072Z"
          fill="#CB3837"
        />
      </svg>
    </StyledSvg>
  );
};
